import './App.css';
import './animate.css';
import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeFull from "./components/HomeFull";
import FaqRender from "./components/faqrender";
import AboutMeRender from "./components/aboutmerender";
import AstrologyReadingsRender from "./components/astrologyreadingsrender";
import BooksRender from "./components/booksrender";
import ContactRender from "./components/contactrender";
import { AlertProvider } from "./context/alertContext";
import Alert from "./components/Alert";
import { ChakraProvider } from "@chakra-ui/react";

function App() {

  return (
    <ChakraProvider>
      <AlertProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeFull />} />
            <Route path="/astrologyreadings" element={<AstrologyReadingsRender />} />
            <Route path="/books" element={<BooksRender />} />
            <Route path="/faq" element={<FaqRender />} />
            <Route path="/aboutme" element={<AboutMeRender />} />
            <Route path="/contact" element={<ContactRender />} />
          </Routes>
        </BrowserRouter>
        <Alert />
      </AlertProvider>
    </ChakraProvider>
  );
}


export default App;


