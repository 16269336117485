import React, {Component} from 'react';
import WOW from 'wowjs';

import { Text, HStack, VStack, Image, Box, Heading, Link, Flex, Button } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import bookpic from "../images/bookpic.png";
import amazonIcon from "../images/amazonicon.png";
import workshop1pic from "../images/workshop1.jpg";
import workshop2pic from "../images/workshop2.jpg";

const book1 = "Achieving Mindfulness Through Astrology";
const book1_detail = "This book can help you during these times of dramatically changing energy, as it guides you in how to use your natal chart to attain peace and stability. It explains how your astrology sign can help you decide on the best way to approach and achieve mindfulness. This book is for anyone who wants to learn more about mindfulness and is eager to improve their well-being, as well as those who are interested in astrology and what role it plays in your life.";
const book1_link = "https://www.amazon.co.uk/Achieving-Mindfulness-Through-Astrology-wellbeing-ebook/dp/B08HHD241F";

const workshop_head = "I offer two types of workshops; those that cover important transits and those that help people understand astrology more fully. No prior astrological knowledge is required. All workshops cost £11 and are recorded so you can watch them in your own time.";
const workshop1 = "How Astrology Works";
const workshop1_detail = "The first workshop is called 'How Astrology Works' which explains the energy underlying everything and its effects on the natal chart, including the latest research on quantum theories, and how these link to astrology.";
const workshop1_detail2 = "On this workshop, I describe energy and look in detail at the levels of the chart and how the chart can be divided into sections to aid interpretation.";
const workshop1_detail3 = "This workshop will not tell you what the signs or planets mean specifically, instead it will give you an overview of the whole person and how you will be able to see the inner motivations and psychological drives that are the basis of the personality.";
const workshop1_detail4 = "This way of approaching astrology gives a more holistic view of the native, revealing how everything is interconnected and how the chart needs to be considered as a whole, rather than focusing on just the individual interpretations of the signs and planets.";
const workshop2 = "How can you make the most of the Jupiter Uranus conjunction 2024?";
const workshop2_detail = "The conjunction between Jupiter and Uranus in April 2024 has affected everyone both globally and personally. Uranus is the planet of change and of the unexpected. It breaks out of old patterns and brings in something new and different. Jupiter is the planet of expansion and opportunity. It brings good fortune and new possibilities, taking us beyond our current boundaries.";
const workshop2_detail2 = "In this workshop I explain how the planet energies manifest in Taurus, how this new cycle will evolve over the next 12 years and how it might affect things globally, as well as what you can expect to experience on a personal level. Taurus is associated with finances and possessions so globally this cycle will affect the way the economic system works. Personally we will get opportunities and breakthroughs which will change the way we think about our life and bring positive changes. Of course, it is difficult to say exactly what to expect, as Uranus is so unpredictable!";
const workshop2_detail3 = "I will also go through ways to harness and work with this energy to make the most of what it can bring.";

const cardMargin = "1rem";
const cardText = "#091f5b";
const cardBackground = "#fff9ef";
const cardTextSize = "large";
const align="left";
const wide="90vw";
const shadow="2px 2px 20px black";
const cardGaps = "10vh";
const cardStyles = "wow fadeInUp";
const contentStyles = "wow fadeIn";
const wowDuration = "2s";
const headDelay = "0.5s";
const textDelay = "0.3s";
const stackAlign = "start";
const selectPic = "selectPic";
const textAppear = "basicfade";
const boxClasses = "hidden";

function MakeBold({children}) {
    return <span style={{fontWeight: 'bold'}}>{children}</span>;
}

function workshops_click(pic_id){
  const myBox = document.getElementById("dynamicbox" + pic_id);
  const myPic = document.getElementById("workshops" + pic_id);

  for (let i = 1; i <=2; i++){
      var temp = document.getElementById("workshops" + i);
      var temp2 = document.getElementById("dynamicbox" + i);
      temp.className = "selectPic";
      temp2.className = "hidden";
  }

  myPic.className = "highlightPic";
  myBox.className = "revealedbox2";

  const rect = myBox.getBoundingClientRect();
  let y = rect.y;
  y = y + window.scrollY;

  window.scrollTo({top:y - 120,left:0,behavior:"smooth"});
};

function AmazonIcons(){
        return(
            <Image src={amazonIcon} maxHeight="32px"/>
        )
}

class Books extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }



  render() {
    return(
  
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
  >

<Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop="10vh"
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack spacing={8} stackAlign={stackAlign}>
        <Heading
                color={cardText}
                textAlign="center"
                marginLeft={cardMargin}
                marginbottom={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={headDelay}
                
            >
                {book1}
        </Heading>
        <Image src={bookpic} borderRadius={cardMargin} className="wow fadeIn revealhead" data-wow-duration={wowDuration} data-wow-delay={textDelay} />
        <HStack>
        <Image src={bookpic} borderRadius={cardMargin} className="wow fadeIn hidehead" data-wow-duration={wowDuration} data-wow-delay={textDelay} />
        <Text
            color={cardText} 
            textAlign="center"
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={textDelay}
            fontSize={cardTextSize}>
            {book1_detail}
        </Text>
        </HStack>
    </VStack>
    <Flex 
        marginTop={cardMargin}
        justifyContent="center"
        className={contentStyles}
        data-wow-duration={wowDuration}
        data-wow-delay={textDelay}
    >
        <Link href={book1_link} target="_blank">
            <Button leftIcon={<AmazonIcons />} colorScheme="yellow" position="static"><MakeBold>Buy on Amazon</MakeBold></Button>
        </Link>
    </Flex>
  </Box>


  <Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop="0"
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack spacing={8}>
        <Heading
                color={cardText}
                marginLeft={cardMargin}
                marginbottom={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={headDelay}
                
            >
                Workshops
        </Heading>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={textDelay}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop_head}
        </Text>

        <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}
                textAlign="center"
                marginBottom="0"
                marginTop="0">
        Click an image for more details
      </Text>
        </VStack>
        <VStack marginTop="2rem">
        <Text
                color="white"
                marginLeft={cardMargin}
                marginRight={cardMargin}
                marginbottom={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={headDelay}
                fontSize={25}
                backgroundColor="#091f5b"
                width="100%"
                textAlign="center"
                borderRadius="0.3rem"
            >
                <MakeBold>Understanding Astrology</MakeBold>
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={textDelay}
            fontSize={cardTextSize}
            textAlign="center">
              I am running a series of workshops that explain the principles behind astrology, which will ultimately enable you to have a deeper understanding of yourself, as shown through your natal chart. 
        </Text>   
    </VStack>

    <Flex justifyContent="center" marginTop={cardMargin}>
        <Image margin="0" src={workshop1pic} className={selectPic} id="workshops1" onClick={() => workshops_click("1")}></Image>
    </Flex>

    <VStack id="dynamicbox1" display="none" className={boxClasses}>
            <Text
                color={cardText}
                marginLeft={cardMargin}
                marginbottom={cardMargin}
                className={textAppear}
                fontSize={25}
                textAlign="center"
                
            >
                <MakeBold>{workshop1}</MakeBold>
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop1_detail}
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop1_detail2}
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop1_detail3}
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop1_detail4}
        </Text>
    </VStack>
    
    <VStack marginTop="2rem">
        <Text
                color="white"
                marginLeft={cardMargin}
                marginRight={cardMargin}
                marginbottom={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={headDelay}
                fontSize={25}
                backgroundColor="#091f5b"
                width="100%"
                textAlign="center"
                borderRadius="0.3rem"
            >
                <MakeBold>Transits</MakeBold>
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={textDelay}
            fontSize={cardTextSize}
            textAlign="center">
             Workshops on important transits help you to understand them and relate them to your own life. Because the major transits between planets are part of a cycle, it doesn't matter when you obtain the information as it will be relevant for the whole of the cycle. 
        </Text>   
    </VStack>

    <Flex justifyContent="center" marginTop={cardMargin} marginBottom="0">
        <Image src={workshop2pic} className={selectPic} id="workshops2" onClick={() => workshops_click("2")}></Image>
    </Flex>

    <VStack spacing={8} id="dynamicbox2" display="none" className={boxClasses}>
            <Text
                color={cardText}
                marginLeft={cardMargin}
                marginbottom={cardMargin}
                className={textAppear}
                fontSize={25}
                textAlign="center"
            >
                <MakeBold>{workshop2}</MakeBold>
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop2_detail}
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop2_detail2}
        </Text>
        <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginTop={cardMargin}
            className={textAppear}
            fontSize={cardTextSize}
            textAlign="center">
            {workshop2_detail3}
        </Text>
    </VStack>

  </Box>

  </FullScreenSection>
    )
  }
}

export default Books;