import Header from "./header";
import HomePage2 from "./homePage2";
import { ChakraProvider } from "@chakra-ui/react";

const HomeFull = () => {
    return(
        <ChakraProvider>
                <main id="home">
                    <HomePage2 />
                    <Header />
                </main>
        </ChakraProvider>
    );
};

export default HomeFull;