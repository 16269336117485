import Header from "./header";
import ContactUs from "./contact";
import ContactTop from "./contacttop";
import { ChakraProvider } from "@chakra-ui/react";

const ContactRender = () => {
    return(
        <ChakraProvider>
                <main id="home">
                    <ContactTop />
                    <ContactUs />
                    <Header />
                </main>
        </ChakraProvider>
    );
};

export default ContactRender;