import Header from "./header";
import FaqPage from "./faq2";
import FaqTop from "./faqtop";
import { ChakraProvider } from "@chakra-ui/react";

const FaqRender = () => {
    return(
        <ChakraProvider>
                <main id="home">
                    <FaqTop />
                    <FaqPage />
                    <Header />
                </main>
        </ChakraProvider>
    );
};

export default FaqRender;