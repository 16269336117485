import React, {Component, useState} from 'react';
import WOW from 'wowjs';
import { Text, VStack, Box, Heading, UnorderedList, ListItem } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

function TextBody(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            Astrology is the study of the celestial bodies (stars and planets) and their link to events on Earth. It is based on cosmic cycles; cycles of time which measure the movement of all objects in the heavens. The energy of the stars and planets affects the energy across the whole universe, and although this doesn't cause events to happen, it reflects the correlation of what we experience on Earth. For example, a clock reflects that the time is 8.30 am - you know this means that the traffic could potentially be heavy and your journey will take you ages. The clock doesn't cause the traffic, however, it just reflects the potential experience you might have.
          </Text>
  </div>
  )
}

function TextBody2(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            Astrology has been used for thousands of years to gain insight and knowledge about ourselves and perspective concerning our situation. We use it for guidance and advice, for self-awareness and help in understanding our actions. Making use of astrology enables you to get in touch with your own resources, make informed choices and take responsibility for your life. It can reveal any behaviour patterns that may be the cause of unconscious fears and instinctive motivations. These subconscious behaviour patterns are formed by your early formative experiences when you learned how to react to your environment and also from past life influences which are still affecting you. Creating a birth chart can tell you so many things, from karmic lessons of the past to what the future holds for you.
          </Text>
  </div>
  )
}

function TextBody3(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            {"A birth (or natal) chart is a representation of the sky at the moment you were born. It is a map of the Sun, the Moon and the planets, their relationship with one another and their relationship with the geographical place you were born. It is like a photograph of the heavens at that point; the planets are 'frozen' on the chart map. It shows the cosmic energy that existed at your time of birth, as a reflection of your whole potential. Your birth chart is completely unique to you and can reveal your strengths opportunities, skills, anxieties, challenges and potentials."}
          </Text>
  </div>
  )
}

function TextBody4(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            {"To create (or cast) a natal chart I need your date, place and time of birth."}
          </Text>
  </div>
  )
}

function TextBody5(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            {"Even without a birth time, there are still many important themes we can work with. It might be possible to work out your birth time, however, especially if you have a vague idea (around lunchtime, or early evening, for example). If you would like a consultation but don't know your birth time then contact me and we can discuss your options."}
          </Text>
  </div>
  )
}

function TextBody6(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            {"The consultations are done online via Zoom. A recording of the reading is sent to you, along with an image of your chart. Alternatively, I can write a report which is either emailed or I can send a printed copy in a folder (at a small extra cost to cover P&P)"}
          </Text>
  </div>
  )
}

function TextBody7(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            Because a birth chart can reveal an enormous amount of information, it is easier to work in themes and focus on a specific area in detail. By splitting the reports into themes, it allows a comprehensive guide to individual areas of life, allowing you to focus on what is most relevant to you at that particular time. All readings generally cover the basic energy of the chart but we can then tailor things to give you the relevant information you need. They generally last for about an hour.
          </Text>
  </div>
  )
}

function TextBody8(){
  return(
  <div className={textAppear} marginBottom={cardMargin}>
  <Text
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            marginBottom={cardMargin}
            fontSize={cardTextSize}>
            Consultations cost £55 when done via zoom or emailed as a written report. If you would like a printed report then there is an additional £5 cost added for P&P.
          </Text>
  </div>
  )
}

function TextBody9(){
  return(
    <div className={textAppear} marginBottom={cardMargin}>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginBottom={cardMargin}
          fontSize={cardTextSize}>
          The natal chart covers the whole potential of your life, but the themes people are mostly interested in are:
        </Text>
    <UnorderedList
            color={cardText}
            fontSize={cardTextSize}
            marginLeft={cardMargin}
            marginright={cardMargin}
            marginBottom={cardMargin}
            spacing={8}>
            <ListItem><MakeBold>Natal Chart interpretation</MakeBold> - this helps you make sense of your life by giving you self-awareness. You can understand the repeating patterns you keep encountering, what skills you are not fully utilising and reveals the best way for you to move forward. You can take control of your life and consciously develop your strengths and understand your challenges</ListItem>
            <ListItem><MakeBold>Soul Path and Karma</MakeBold> - this outlines your spiritual path and reveals any karma that is affecting you. It enables you to be aware of how you are working on your life lessons, and how you can change, grow and develop on your journey towards your soul's purpose</ListItem>
            <ListItem><MakeBold>Life Development</MakeBold> - this reveals the psychological growth throughout your life as influenced by the planetary cycles, which allows you to consciously perceive and understand that life is a process of progression and development and how all parts of your life fit together as a whole</ListItem>
            <ListItem><MakeBold>Transits and future influences</MakeBold> - this reveals what energies are coming into your life and how they will affect you, what areas of life are going to be important in the next few months and how best to prepare for them</ListItem>
            <ListItem><MakeBold>Astrology for Children</MakeBold> - this helps parents understand their child, become aware of strengths and any potential concerns, and gives the skills to guide children in the best possible way to help them fulfil their potential</ListItem>
    </UnorderedList>
    <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          fontSize={cardTextSize}>
          There are many other ways of using the chart for information, so if you would like to know anything that I haven't mentioned then please contact me to discuss this.
        </Text>
    </div>
  )
}

function TextBody10(){
  return(
    <div className={textAppear} marginBottom={cardMargin}>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginBottom={cardMargin}
          fontSize={cardTextSize}>
          I do <MakeBold>life coaching sessions</MakeBold> that don't involve astrology.
        </Text>
        <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginBottom={cardMargin}
          fontSize={cardTextSize}>
          I can <MakeBold>work with your animal</MakeBold>, helping with any behavioural issues using a mix of traditional behaviour modification techniques and animal communication. (I also use Dispositor Astrology where appropriate.)
        </Text>
        <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginBottom={cardMargin}
          fontSize={cardTextSize}>
          I do <MakeBold>personal teaching sessions</MakeBold> - if you would like to know more about astrology and don't know where to start, or if you already have some knowledge but want to understand specific things in more detail.
        </Text>
        <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginBottom={cardMargin}
          fontSize={cardTextSize}>
          I offer a <MakeBold>'Learn Astrology for Beginners' course</MakeBold>, in which individual lessons are emailed, with 'homework' to be completed before the next lesson.
        </Text>
        <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          fontSize={cardTextSize}>
          I hold <MakeBold>workshops</MakeBold> covering various topics - follow me on Facebook and Instagram to find out about these
        </Text>
    </div>
  )
}

const faqHead = "What is astrology?";
const faqHead2 = "How can astrology help me?";
const faqHead3 = "What is a birth chart?";
const faqHead4 = "What information do you need to cast a birth chart?";
const faqHead5 = "What if I don't know my birth time?";
const faqHead6 = "Are the consultations done in person?";
const faqHead7 = "What is covered in a consultation?";
const faqHead8 = "How much do consultations cost?";
const faqHead9 = "What specific themes are there?";
const faqHead10 = "Do you offer anything else?";

const cardMargin = "1rem";
const cardText = "#091f5b";
const cardBackground = "#fff9ef";
const cardTextSize = "large";
const align="left";
const shadow="2px 2px 20px black";
const cardGaps = "5vh";
const cardStyles = "wow fadeInUp";
const contentStyles = "wow fadeIn";
const faqStyle = "wow fadeIn faq";
const wowDuration = "2s";
const headDelay = "0.5s";
const textDelay = "0.5s";
const stackAlign = "start";
const textAppear = "basicfade";

function MakeBold({children}) {
    return <span style={{fontWeight: 'bold'}}>{children}</span>;
}

function FAQFUNCTION(){
    const [currentfaq, setCurrentfaq] = useState(null);

    function faq_select(faq_id){
      if(currentfaq === faq_id){
        setCurrentfaq(null);
      } else{
      setCurrentfaq(faq_id);
      }
    }

    return(
      <FullScreenSection
      justifyContent="center"
      alignItems="center"
    >
  
    <Box
    padding={cardMargin}
    alignItems={align}
    backgroundColor={cardBackground}
    borderRadius={cardMargin}
    width="80vw"
    boxShadow={shadow}
    marginTop="10vh"
    marginBottom={cardGaps}
    className={cardStyles}
    data-wow-duration={wowDuration}
    >
      <VStack spacing={8} align={stackAlign} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(1)}
          >
              {currentfaq === 1 ? '- ' : '+ '}
              {faqHead}
          </Heading>
            {currentfaq === 1 ? <TextBody /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
  
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(2)}
          >
              {currentfaq === 2 ? '- ' : '+ '}
              {faqHead2}
          </Heading>
            {currentfaq === 2 ? <TextBody2 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
   
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(3)}
          >
              {currentfaq === 3 ? '- ' : '+ '}
              {faqHead3}
          </Heading>
            {currentfaq === 3 ? <TextBody3 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
    
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(4)}
          >
              {currentfaq === 4 ? '- ' : '+ '}
              {faqHead4}
          </Heading>
            {currentfaq === 4 ? <TextBody4 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
    
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(5)}
          >
              {currentfaq === 5 ? '- ' : '+ '}
              {faqHead5}
          </Heading>
            {currentfaq === 5 ? <TextBody5 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
    
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(6)}
          >
              {currentfaq === 6 ? '- ' : '+ '}
              {faqHead6}
          </Heading>
            {currentfaq === 6 ? <TextBody6 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
    
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(7)}
          >
              {currentfaq === 7 ? '- ' : '+ '}
              {faqHead7}
          </Heading>
            {currentfaq === 7 ? <TextBody7 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
    
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(8)}
          >
              {currentfaq === 8 ? '- ' : '+ '}
              {faqHead8}
          </Heading>
          {currentfaq === 8 ? <TextBody8 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
   
      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(9)}
          >
              {currentfaq === 9 ? '- ' : '+ '}
              {faqHead9}
          </Heading>
        {currentfaq === 9 ? <TextBody9 /> : ''}
      </VStack>

      <hr style={{border: "1px solid #091f5b"}}></hr>

      <VStack spacing={8} align={stackAlign} marginTop={cardMargin} marginBottom={cardMargin}>
          <Heading
              color={cardText}
              marginLeft={cardMargin}
              marginbottom={cardMargin}
              className={faqStyle}
              data-wow-duration={wowDuration}
              data-wow-delay={headDelay}
              onClick={() => faq_select(10)}
          >
              {currentfaq === 10 ? '- ' : '+ '}
              {faqHead10}
          </Heading>
        {currentfaq === 10 ? <TextBody10 /> : ''}
      </VStack>
  
      <hr style={{border: "1px solid #091f5b"}}></hr>
    
      <VStack spacing={8} align={stackAlign} marginTop="2rem" marginBottom={cardMargin}>
        <Text
            alignContent="center" 
            color={cardText} 
            marginLeft={cardMargin} 
            marginRight={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={textDelay}
            fontSize={cardTextSize}>
            Please contact me if you have any questions about anything - I am always happy to help!
          </Text>
      </VStack>
    </Box>
  
    </FullScreenSection>
    )
}

class FaqPage extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return(
      <FAQFUNCTION />
    )
  }
}

export default FaqPage;