import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { useFormik } from "formik";
import { Text, VStack, Box, FormControl, FormLabel, FormErrorMessage, Input, Button, Textarea} from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import * as Yup from 'yup';
import {useAlertContext} from "../context/alertContext";

const cardMargin = "1rem";
const cardText = "#091f5b";
const cardBackground = "#fff9ef";
const cardTextSize = "large";
const align="left";
const wide="90vw";
const shadow="2px 2px 20px black";
const cardStyles = "wow fadeInUp";
const contentStyles = "wow fadeIn";
const wowDuration = "2s";
const headDelay = "0.5s";
const textDelay = "0.5s";

  function MakeBold({children}) {
    return <span style={{fontWeight: 'bold'}}>{children}</span>;
}

function ContactUs() {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { onOpen } = useAlertContext();

  const form = useRef();

  const formik = useFormik({
    initialValues: {user_name:"", user_email:"", message:""},
    validationSchema: Yup.object({
      user_name: Yup.string().required('Required'),
      user_email: Yup.string().email('Invalid email address').required('Required'),
      message: Yup.string().required('Required')
    }),
  });

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm('service_qlcuwzj', 'template_s1gk7rl', form.current, {
        publicKey: 'z-KJZSWUWBi1etMSI',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setResponse({
          type: 'success',
          txt: `Thanks for getting in touch. I will get back to you shortly!`,
          })
        },
        (error) => {
          console.log('FAILED...', error.text);
          setResponse({
          type: 'error',
          txt: "Something went wrong! If the form doesn't work for you, send an email directly to karenmarshastrology@gmail.com",
          })
        },
      );
  };
  
  useEffect(() => {
    if (response != null){
      onOpen(response.type, response.txt)
      if(response.type === "success"){
        formik.resetForm()
      }
      setLoading(false);
    }
  },[response]);

  return (
<FullScreenSection
    justifyContent="center"
    alignItems="center"
    marginTop="50px"
  >
<Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  boxShadow={shadow}
  marginTop="0"
  marginBottom="0"
  className={cardStyles}
  data-wow-duration={wowDuration}
  maxWidth={wide}
  >
    <VStack>
      <Text
        color={cardText} 
        marginLeft={cardMargin} 
        marginRight={cardMargin}
        className={contentStyles}
        data-wow-duration={wowDuration}
        data-wow-delay={textDelay}
        fontSize={cardTextSize}>
          To get in touch with me, please either fill out the form below or send an email to <MakeBold>karenmarshastrology@gmail.com</MakeBold>
        </Text>
    </VStack>
  </Box>

    <Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  boxShadow={shadow}
  marginTop="5rem"
  marginBottom="0"
  className={cardStyles}
  data-wow-duration={wowDuration}
  maxWidth={wide}
  >
<form ref={form} onSubmit={sendEmail}>
  <FormControl marginBottom={cardMargin} isInvalid={formik.touched.user_name && formik.errors.user_name}>
    <FormLabel
            color={cardText}
            marginbottom={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={headDelay}
            fontSize={cardTextSize}
            fontWeight="bold"
            >
            Name
    </FormLabel>  
    <Input 
        type='text'
        id="user_name"
        name="user_name"
        color={cardText}
        marginbottom={cardMargin}
        className={contentStyles}
        data-wow-duration={wowDuration}
        data-wow-delay={headDelay}
        fontSize={cardTextSize}
        borderColor={cardText}
        {...formik.getFieldProps('user_name')}
    />
    <FormErrorMessage>{formik.errors.user_name}</FormErrorMessage>
  </FormControl>  
  <FormControl marginBottom={cardMargin} isInvalid={formik.touched.user_email && formik.errors.user_email}> 
  <FormLabel
            color={cardText}
            marginbottom={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={headDelay}
            fontSize={cardTextSize}
            fontWeight="bold"
            >
            Email
    </FormLabel>
    <Input 
        type='email'
        id="user_email"
        name="user_email"
        color={cardText}
        marginbottom={cardMargin}
        className={contentStyles}
        data-wow-duration={wowDuration}
        data-wow-delay={headDelay}
        fontSize={cardTextSize}
        borderColor={cardText}
        {...formik.getFieldProps('user_email')}
    />
    <FormErrorMessage>{formik.errors.user_email}</FormErrorMessage>
  </FormControl>
  <FormControl marginBottom={cardMargin} >
  <FormLabel
            color={cardText}
            marginbottom={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={headDelay}
            fontSize={cardTextSize}
            fontWeight="bold"
            >
            Message
    </FormLabel>
    <Textarea 
        name="message"
        id="message"
        color={cardText}
        marginbottom={cardMargin}
        className={contentStyles}
        data-wow-duration={wowDuration}
        data-wow-delay={headDelay}
        fontSize={cardTextSize}
        borderColor={cardText}
        height="200px"
        overflow="scroll"
        {...formik.getFieldProps('message')}
    />
  </FormControl>
    <Button 
        type="submit" 
        value="Send"
        colorScheme="blue"
        bg={cardText}
        color={cardBackground}
        isDisabled={!formik.touched.user_name || !formik.touched.user_email || formik.errors.user_name || formik.errors.user_email || isLoading}
        >
            {isLoading ? 'Loading, please wait...' : 'Submit'}
        </Button>
        <Text
        color="red" 
        marginLeft="0"
        marginRight={cardMargin}
        className={contentStyles}
        data-wow-duration={wowDuration}
        data-wow-delay={textDelay}
        fontSize="medium">
          {!formik.touched.user_name || !formik.touched.user_email || formik.errors.user_name || formik.errors.user_email ? 'You must complete the form before you can press the submit button' : ''}
        </Text>
</form>
</Box>
</FullScreenSection>
  );
};

export default ContactUs;