import React, {Component} from 'react';
import WOW from 'wowjs';

import { Text, VStack, Image, Box, Heading, Flex } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import pic1 from "../images/readings_natalchart.jpg";
import pic2 from "../images/readings_soulpath.jpg";
import pic3 from "../images/readings_lifedevelopment.jpg";
import pic4 from "../images/readings_transits.jpg";
import pic5 from "../images/readings_children.jpg";

const textBody = "Having an astrology reading can help you to understand your life. It encourages the process of self-discovery and can uncover your potential for psychological and spiritual growth, giving insight to any challenges or recurring patterns you have experienced throughout your life. It can also show how you can make progress on your soul path in this lifetime.";
const textBody2 = "Astrology consultations use the information obtained from your natal chart. A natal (or birth) chart is a representation of the sky at the moment you were born. It is a map of the Sun, the Moon and the planets, their relationship with one another and their relationship with the geographical place you were born. It is like a photograph of the heavens at that point; the planets are 'frozen' on the chart map. It shows the cosmic energy that existed at your time of birth, as a reflection of your whole potential. Your birth chart is completely unique to you and can reveal your strengths opportunities, skills, anxieties, challenges and potentials. It can tell you so many things, from karmic lessons of the past to what the future holds for you.";
const textBody3 = "Because a birth chart can reveal an enormous amount of information, it is easier to work in themes and focus on a specific area in detail. By splitting the reports into themes, it allows a comprehensive guide to individual areas of life, allowing you to focus on what is most relevant to you at that particular time. All readings generally cover the basic energy of the chart but we can then tailor things to give you the relevant information you need.";
const textBody4 = "The consultations are done online via Zoom, last approximately an hour and cost £55. A recording of the reading is sent to you, along with an image of your chart. The consultations can be written reports if preferred. Written reports are emailed or I can send a printed copy in a folder (at a small extra cost to cover P&P)";

const headerText = "Consultations";

const cardMargin = "1rem";
const cardText = "#091f5b";
const cardBackground = "#fff9ef";
const cardTextSize = "large";
const align="left";
const wide="90vw";
const shadow="2px 2px 20px black";
const cardGaps = "10vh";
const cardStyles = "wow fadeInUp";
const contentStyles = "wow fadeIn";
const wowDuration = "2s";
const headDelay = "0.5s";
const textDelay = "0.3s";
const stackAlign = "center";
const selectPic = "selectPic";
const textAppear = "basicfade";
const boxClasses = "hidden";

const read1 = "Natal Chart interpretation";
const read2 = "Soul Path and Karma";
const read3 = "Life Development";
const read4 = "Transits and future influences";
const read5 = "Astrology for Children";
const read1_body = "This encourages self-awareness and reveals your psychological make-up and inner motivations, why you have repeating patterns in your life, your instinctive drives, how your environment has affected you. It helps you to take control of your life and consciously develop your strengths and understand your challenges";
const read2_body = "This outlines your spiritual path and reveals any karma that is affecting you. It enables you to be aware of how you are working on your life lessons, and how you can change, grow and develop on your journey towards your soul's purpose";
const read3_body = "This reveals the psychological growth throughout your life as influenced by the planetary cycles. Knowledge of these allow you to consciously perceive and understand that life is a process of progression and development and understand how all parts of your life fit together as a whole";
const read4_body = "This reveals what energies are coming into your life and how they will affect you, what areas of life are going to be important in the coming months and how best to prepare for them. Transits are provided as a written report unless you specifically request an in-person session";
const read5_body = "This helps parents understand their child, become aware of strengths and any potential concerns, and gives the skills to guide children in the best possible way to help them fulfil their potential. Astrology for Children is only provided as a written report";

function MakeBold({children}) {
    return <span style={{fontWeight: 'bold'}}>{children}</span>;
}

function readings_click(pic_id){
  const myBox = document.getElementById("dynamicbox" + pic_id);
  const myPic = document.getElementById("reading" + pic_id);

  for (let i = 1; i <=5; i++){
      var temp = document.getElementById("reading" + i);
      var temp2 = document.getElementById("dynamicbox" + i);
      temp.className = "selectPic";
      temp2.className = "hidden";
  }

  myPic.className = "highlightPic";
  myBox.className = "revealedbox";

  window.scrollTo({top:document.body.scrollHeight,left:0,behavior:"smooth"});
}

class AstrologyReadingsPage extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }



  render() {
    return(
  
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
  >

<Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop="10vh"
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack spacing={8} align={stackAlign}>
      <Text
          color={cardText} 
          textAlign="center"
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className={contentStyles}
          data-wow-duration={wowDuration}
          data-wow-delay={textDelay}
          fontSize={cardTextSize}>
          {textBody}
        </Text>
    </VStack>
  </Box>

  <Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop="0vh"
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack spacing={8} align={stackAlign}>
        <Heading
            color={cardText}
            textAlign="center"
            marginLeft={cardMargin}
            marginbottom={cardMargin}
            className={contentStyles}
            data-wow-duration={wowDuration}
            data-wow-delay={headDelay}
        >
            {headerText}
        </Heading>
      <Text
          color={cardText} 
          textAlign="center"
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className={contentStyles}
          data-wow-duration={wowDuration}
          data-wow-delay={textDelay}
          fontSize={cardTextSize}>
          {textBody2}
        </Text>
        <Text
          color={cardText} 
          textAlign="center"
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className={contentStyles}
          data-wow-duration={wowDuration}
          data-wow-delay={textDelay}
          fontSize={cardTextSize}>
          {textBody3}
        </Text>
        <Text
          color={cardText} 
          textAlign="center"
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className={contentStyles}
          data-wow-duration={wowDuration}
          data-wow-delay={textDelay}
          fontSize={cardTextSize}>
          {textBody4}
        </Text>
    </VStack>
  </Box>
 
    <Box
    padding={cardMargin}
    alignItems={align}
    backgroundColor={cardBackground}
    borderRadius={cardMargin}
    maxWidth={wide}
    boxShadow={shadow}
    marginTop="0vh"
    marginBottom={cardGaps}
    className={cardStyles}
    data-wow-duration={wowDuration}
    >
      <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}
                textAlign="center"
                marginBottom="1rem"
                marginTop="0">
        Click an image for more details
      </Text>
         <Flex gap={10} flexWrap="wrap" justifyContent="center">
            <VStack>
                <Image src={pic1} className={selectPic} id="reading1" onClick={() => readings_click("1")}></Image>
                <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                <MakeBold>{read1}</MakeBold>
                </Text>
            </VStack>
            <VStack>
                <Image src={pic2} className={selectPic} id="reading2" onClick={() => readings_click("2")}></Image>
                <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                <MakeBold>{read2}</MakeBold>
                </Text>
            </VStack>
            <VStack>
                <Image src={pic3} className={selectPic} id="reading3" onClick={() => readings_click("3")}></Image>
                <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                <MakeBold>{read3}</MakeBold>
                </Text>
            </VStack>
            <VStack>
                <Image src={pic4} className={selectPic} id="reading4" onClick={() => readings_click("4")}></Image>
                <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                <MakeBold>{read4}</MakeBold>
                </Text>
            </VStack>
            <VStack>
                <Image src={pic5} className={selectPic} id="reading5" onClick={() => readings_click("5")}></Image>
                <Text
                color={cardText} 
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                <MakeBold>{read5}</MakeBold>
                </Text>
            </VStack>
        </Flex>

      <VStack id="dynamicbox1" display="none" className={boxClasses}>
      <Heading
            color={cardText}
            marginLeft={cardMargin}
            marginbottom={cardMargin}
            className={textAppear}
            textAlign="center"
        >
            {read1}
        </Heading>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginTop={cardMargin}
          className={textAppear}
          fontSize={cardTextSize}
          textAlign="center">
            {read1_body}
        </Text>
      </VStack>

      <VStack id="dynamicbox2" display="none" className={boxClasses}>
      <Heading
            color={cardText}
            marginLeft={cardMargin}
            marginbottom={cardMargin}
            className={textAppear}
            textAlign="center"
        >
            {read2}
        </Heading>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginTop={cardMargin}
          className={textAppear}
          fontSize={cardTextSize}
          textAlign="center">
            {read2_body}
        </Text>
      </VStack>

      <VStack id="dynamicbox3" display="none" className={boxClasses}>
      <Heading
            color={cardText}
            marginLeft={cardMargin}
            marginbottom={cardMargin}
            className={textAppear}
            textAlign="center"
        >
            {read3}
        </Heading>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginTop={cardMargin}
          className={textAppear}
          fontSize={cardTextSize}
          textAlign="center">
            {read3_body}
        </Text>
      </VStack>

      <VStack id="dynamicbox4" display="none" className={boxClasses}>
      <Heading
            color={cardText}
            marginLeft={cardMargin}
            marginbottom={cardMargin}
            className={textAppear}
            textAlign="center"
        >
            {read4}
        </Heading>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginTop={cardMargin}
          className={textAppear}
          fontSize={cardTextSize}
          textAlign="center">
            {read4_body}
        </Text>
      </VStack>

      <VStack id="dynamicbox5" display="none" className={boxClasses}>
      <Heading
            color={cardText}
            marginLeft={cardMargin}
            marginbottom={cardMargin}
            className={textAppear}
            textAlign="center"
        >
            {read5}
        </Heading>
      <Text
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          marginTop={cardMargin}
          className={textAppear}
          fontSize={cardTextSize}
          textAlign="center">
            {read5_body}
        </Text>
      </VStack>
    </Box>

    

  </FullScreenSection>
    )
  }
}

export default AstrologyReadingsPage;