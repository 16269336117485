import Header from "./header";
import AboutMePage from "./aboutme";
import AboutMeTop from "./aboutmetop";
import { ChakraProvider } from "@chakra-ui/react";

const AboutMeRender = () => {
    return(
        <ChakraProvider>
                <main id="home">
                    <AboutMeTop/>
                    <AboutMePage />
                    <Header />
                </main>
        </ChakraProvider>
    );
};

export default AboutMeRender;