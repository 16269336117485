import React, {Component} from 'react';
import WOW from 'wowjs';
import profilepic from '../images/aboutme.jpg';

import { Heading, VStack, Image} from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const greeting = "About Me";
const myPic = profilepic

class AboutMeTop extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return(
    
  <FullScreenSection
    justifyContent="end"
    alignItems="center"
    minHeight="70vh"
  >
  <VStack color="#fff9ef">
    <Heading fontSize="xxx-large" textShadow="3px 3px 2px black" className="wow fadeIn" data-wow-duration="2s">{greeting}</Heading>
    <Image src={myPic} boxSize="200px" borderRadius="full" className="wow fadeIn" data-wow-duration="2s"/>
  </VStack>


  </FullScreenSection>
    )
  }
}

export default AboutMeTop;