import Header from "./header";
import Books from "./books";
import BooksTop from "./bookstop";
import { ChakraProvider } from "@chakra-ui/react";

const BooksRender = () => {
    return(
        <ChakraProvider>
                <main id="home">
                    <BooksTop/>
                    <Books />
                    <Header />
                </main>
        </ChakraProvider>
    );
};

export default BooksRender;