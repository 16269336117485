import React, {Component} from 'react';
import WOW from 'wowjs';

import { Text, VStack, Box, Link } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const textBody = "I have been drawn to 'alternative' knowledge and practices from a very young age - at 12 years old I started learning tarot and astrology and also studied hieroglyphics. I have continued with these interests all my life and as well as qualifying in astrology with White Eagle Lodge, I am a reiki master and have studied herbs, crystals, palmistry, chakra healing and auras. ";
const textBody2 = "I have had a very eclectic working background with many varied qualifications! I have been a biochemist, a college tutor, a school network manager, a regional manager for an education IT company and a life coach. Throughout this time I also joined Mensa, qualified in animal behaviour and have been an animal behaviourist, dog trainer and animal communicator. ";
const textBody3 = "I created 'Merkarna', a range of natural herbal based products that help to balance and restore our vital energy. Energy is the invisible force that surrounds the human body and permeates everything in the natural world, including animals, plants, trees and rocks. Using my knowledge as a biochemist and herbalist I wanted to offer everyone an affordable, and completely natural and safe, range of luxurious yet useful products. These are available at White Thyme - ";
const textBody4 = "Alongside everything I have done I have always continued with astrology and spiritual practices. I have also kept up-to-date with research on quantum physics, the study of consciousness, how everything is interconnected and the law of attraction; together all these skills help me adapt my approach to guiding others on their own life path. ";
const textBody5 = "My dream is for everyone to be self-aware, recognising the power of knowledge and wisdom; to understand themselves and why they do what they do, and to appreciate the world and everything it contains. Astrology is a step that can lead you on an amazing journey of self-discovery, and helps you see your place in the universe. I would love you to take that step with me! ";

const cardMargin = "1rem";
const cardText = "#091f5b";
const cardBackground = "#fff9ef";
const cardTextSize = "large";
const align="left";
const wide="90vw";
const shadow="2px 2px 20px black";
const cardGaps = "0vh";
const cardStyles = "wow fadeInUp";
const contentStyles = "wow fadeIn";
const wowDuration = "2s";
const textDelay = "0.3s";
const stackAlign = "center";


class AboutMePage extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return(
  
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    minHeight="70vh"
    marginTop="50px"
  >

        <Box
        padding={cardMargin}
        alignItems={align}
        backgroundColor={cardBackground}
        borderRadius={cardMargin}
        maxWidth={wide}
        boxShadow={shadow}
        marginTop="0"
        marginBottom={cardGaps}
        className={cardStyles}
        data-wow-duration={wowDuration}
        >
            <VStack spacing={8} align={stackAlign}>
            <Text
                color={cardText} 
                textAlign="center"
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                {textBody}
            </Text>
            <Text
                color={cardText} 
                textAlign="center"
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                {textBody2}
            </Text>
            <Text
                color={cardText}
                textAlign="center"
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                {textBody3}
                <Link color="blue" href="https://whitethyme.co/" target="_blank">https://whitethyme.co/</Link>
            </Text>
            <Text
                color={cardText} 
                textAlign="center"
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                {textBody4}
            </Text>
            <Text
                color={cardText} 
                textAlign="center"
                marginLeft={cardMargin} 
                marginRight={cardMargin}
                className={contentStyles}
                data-wow-duration={wowDuration}
                data-wow-delay={textDelay}
                fontSize={cardTextSize}>
                {textBody5}
            </Text>
            </VStack>
        </Box>

  </FullScreenSection>
    )
  }
}

export default AboutMePage;