import React, {useState, useEffect} from "react";
import { Box, HStack, Image } from "@chakra-ui/react";
import facebookicon from '../images/facebookicon.png';
import instagramicon from '../images/instagramicon.png';
import logo from '../images/logo_trans.png';
import spacebackground from '../images/space2.jpg';
import { Outlet, Link } from "react-router-dom";

const socials = [
    {
      icon: facebookicon,
      url: "https://www.facebook.com/KarenMarshAstrology",
    },
    {
      icon: instagramicon,
      url: "https://www.instagram.com/karen_marsh_astrology/",
    },
  ];
  
  function Header(){
    const [scrollPosition, setScrollPosition] = useState(0);
    const [headerY, setHeaderY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return() => {
          window.removeEventListener('scroll', handleScroll)
        }
      })
    
      const handleScroll = (e) => {
        let x = window.innerWidth;
        let y = x > 850 ? -260 : 0;
        window.scrollY > 0 ? setHeaderY(y) : setHeaderY(0);
        setScrollPosition(window.scrollY);
      }

      const handleClick = (e) => () => {
        window.scrollTo({top:0,left:0,behavior:"instant"});
        setHeaderY(0);
        setScrollPosition(0);
      };
    
      return (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          transform="auto"
          translateY={headerY}
          transitionProperty="transform"
          transitionDuration="1s"
          transitionTimingFunction="ease-in-out"
          backgroundImage={spacebackground}
          backgroundColor="white"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
        >
          <Image src={logo} maxWidth="300px" marginLeft="auto" marginRight="auto" className="logoimg"/>
    
          <Box color="white" maxWidth="1920px" margin="0 auto" backgroundColor="#091f5b" alignContent="center">
          
            <HStack
              px={0}
              py={5}
              justifyContent="center"
              alignItems="center"
            >
              {/*<nav className="hidehead">
                <HStack>
                {socials.map(social => 
                  <a 
                    style={{marginRight: "20px"}}
                    target="_blank"
                    href={social.url}>
                    {<Image src={social.icon} className="sIcon"/>}
                  </a>
                )}
                </HStack>
          </nav>*/}
              <nav>
            <HStack spacing={5} textColor="#fff9ef" wrap="wrap" justifyContent="center">
                {socials.map(social => 
                  <a 
                    style={{marginRight: "20px"}}
                    target="_blank"
                    href={social.url}>
                    {<Image src={social.icon} className="sIcon"/>}
                  </a>
                )}
              <Link to="/" className="navLink" onClick={handleClick()}>Home</Link>
              <Link to="/astrologyreadings" className="navLink" onClick={handleClick()}>Astrology Readings</Link>
              <Link to="/books" className="navLink" onClick={handleClick()}>Books and Workshops</Link>
              <Link to="/faq" className="navLink" onClick={handleClick()}>F.A.Q</Link>
              <Link to="/aboutme" className="navLink" onClick={handleClick()}>About Me</Link>
              <Link to="/contact" className="navLink" onClick={handleClick()}>Contact</Link>
            </HStack>
          </nav>
            <Outlet />

            </HStack>
          </Box>
        </Box>
      );
    
  }

  export default Header;