import React, {Component} from 'react';
import WOW from 'wowjs';

import { Text, HStack, VStack, Image, Box, Heading } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import pic1 from "../images/Picture1.jpg";
import pic2 from "../images/Picture2.png";
import pic3 from "../images/Picture3.jpg";

const greeting = "Welcome to Karen Marsh Astrology";
const quote = "Your life path is illuminated by a roadmap of stars; astrology can guide you";

const textBody2 = "A birth chart captures a moment in that cosmic cycle, almost as a photograph of the heavens at that point; the Sun, the Moon and the planets are 'frozen' on the chart map. After your birth, the planets continue to move in the sky, making new aspects to each other and also changing their relationship to the places they were in your birth chart. This pattern of energy is constantly moving and changing, and throughout your life you will be affected by this - the cosmic cycles are reflected in your own psychological and spiritual development.";
const textBody3 = "Understanding these cycles and their meaning in terms of your life makes astrology a valuable tool to facilitate the process of personal growth. Astrology can help you to cope during times of change, make more informed decisions and discover your life's purpose. ";
const textBody4 = "My dream is for everyone to be self-aware, recognising the power of knowledge and wisdom; to understand themselves and why they do what they do, and to appreciate the universe and everything it contains. Astrology is a step that can lead you on an amazing journey of self-discovery, and helps you see your place in the universe. I would love you to take that step with me!";

const cardMargin = "1rem";
const cardText = "#091f5b";
const cardBackground = "#fff9ef";
const cardTextSize = "large";
const align="left";
const wide="90vw";
const shadow="2px 2px 20px black";
const cardGaps = "10vh";
const cardStyles = "wow fadeInUp";
const wowDuration = "2s"

function MakeItalic({children}) {
  return <span style={{fontStyle:'italic'}}>{children}</span>;
}

class HomePage2 extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return(
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
  >

  <VStack marginTop="40vh" color="#fff9ef">
    <Heading fontSize="xxx-large" textShadow="3px 3px 2px black" marginBottom="2rem" className="wow fadeIn" data-wow-duration="2s" textAlign="center">{greeting}</Heading>
  </VStack>

  <VStack color="#fff9ef" marginBottom={cardGaps}>
    <Heading fontSize="x-large" fontStyle="italic" textShadow="3px 3px 2px black" className="wow fadeIn" data-wow-duration="2s" data-wow-delay="0.5s" textAlign="center" marginLeft={cardMargin} marginRight={cardMargin}>{quote}</Heading>
  </VStack>



  <Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop={cardMargin}
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack>
      <Image src={pic1} borderRadius={cardMargin} className="wow fadeIn revealhead" data-wow-duration="2s" data-wow-delay="1s" maxWidth="200px"/>
    <HStack spacing={8} marginTop="1rem" marginBottom="1rem">
      <Image src={pic1} borderRadius={cardMargin} className="wow fadeIn hidehead" data-wow-duration="2s" data-wow-delay="1s" maxWidth="200px"/>
      <Text
          alignContent="center" 
          textAlign="center"
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className="wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="1.5s"
          fontSize={cardTextSize}>
          Astrology is the study of the celestial bodies (stars and planets) and their link to events on Earth. It is based on cosmic cycles; cycles of time which measure the movement of all objects in the heavens. The energy of the stars and planets affects the energy across the whole universe, and although this doesn't <MakeItalic>cause</MakeItalic> events to happen, it reflects the correlation of what we experience on Earth.
        </Text>
    </HStack>
    </VStack>
  </Box>

  <Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop={cardMargin}
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack>
    <HStack spacing={8} marginTop="1rem" marginBottom="1rem">
      <Text
          alignContent="center" 
          textAlign="center"
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className="wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="1s"
          fontSize={cardTextSize}>
          {textBody2}
        </Text>
        <Image src={pic2} borderRadius={cardMargin} className="wow fadeIn hidehead" data-wow-duration="2s" data-wow-delay="1.5s" />
    </HStack>
      <Image src={pic2} borderRadius={cardMargin} className="wow fadeIn revealhead" data-wow-duration="2s" data-wow-delay="1.5s" />
    </VStack>
  </Box>

  <Box
  padding={cardMargin}
  alignItems={align}
  backgroundColor={cardBackground}
  borderRadius={cardMargin}
  maxWidth={wide}
  boxShadow={shadow}
  marginTop={cardMargin}
  marginBottom={cardGaps}
  className={cardStyles}
  data-wow-duration={wowDuration}
  >
    <VStack spacing={8} marginTop="1rem" marginBottom="1rem">
      <Image src={pic3} borderRadius={cardMargin} className="wow fadeIn revealhead" data-wow-duration="2s" data-wow-delay="1s" maxWidth="300px"/>
    <HStack spacing={8} marginTop="1rem" marginBottom="1rem">
      <Image src={pic3} borderRadius={cardMargin} className="wow fadeIn hidehead" data-wow-duration="2s" data-wow-delay="1s" maxWidth="300px"/>
      <Text
          alignContent="center" 
          textAlign="center"
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className="wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="1.5s"
          fontSize={cardTextSize}>
          {textBody3}
        </Text>
    </HStack>
        <Text
          textAlign="center"
          color={cardText} 
          marginLeft={cardMargin} 
          marginRight={cardMargin}
          className="wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="1.5s"
          fontSize={cardTextSize}>
          {textBody4}
        </Text>
    </VStack>
  </Box>

  </FullScreenSection>
    )
  }
}

export default HomePage2