import Header from "./header";
import AstrologyReadingsPage from "./astrologyreadings";
import AstrologyReadingsTop from "./astrologyreadingstop";
import { ChakraProvider } from "@chakra-ui/react";

const AstrologyReadingsRender = () => {
    return(
        <ChakraProvider>
                <main id="home">
                    <AstrologyReadingsTop/>
                    <AstrologyReadingsPage />
                    <Header />
                </main>
        </ChakraProvider>
    );
};

export default AstrologyReadingsRender;