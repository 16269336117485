import React, {Component} from 'react';
import WOW from 'wowjs';

import { Heading, VStack} from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const greeting = "Contact Me";

class ContactTop extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return(
    
  <FullScreenSection
    justifyContent="end"
    alignItems="center"
    minHeight="50vh"
  >
  <VStack color="#fff9ef">
    <Heading fontSize="xxx-large" textShadow="3px 3px 2px black" className="wow fadeIn" data-wow-duration="2s">{greeting}</Heading>
  </VStack>


  </FullScreenSection>
    )
  }
}

export default ContactTop;